import NextImage, {NextImageProps} from "@components/NextImage";
import {Trans, useTranslation} from "@i18n/client";
import React from "react";
import {v5Pages} from "src/components/_common/_constants";
import {Props as NoticeProps} from "src/components/v5/Maps/Notice";
import {Lookup} from "src/types";

import {ImageExtension} from "../utils/nextImageSource";
import {
  LocationId,
  elCaminoLocIds,
  johnMuirLocIds,
  med7LocationIds,
  saucLocIds,
  stMarysLocIds,
} from "./locations";

export const isFullPageBanner = (item: NoticeProps | Banner): item is NoticeProps =>
  (item as NoticeProps).type !== undefined;

export type LocationInfoBlurb = {
  text: string | JSX.Element;
  icon?: string;
  bg?: string;
  logo?: NextImageProps;
};

export type PartnerLogoType = {
  ids: string[];
  src?: string;
  alt?: string;
  width?: number;
  height?: number;
  ext?: ImageExtension;
  html?: JSX.Element;
};

export type Banner = NoticeProps | LocationInfoBlurb;

type Params = {
  locationId?: string;
};

export const elCaminoDisabledTime = 1630893600000; // Sep. 5, 2021 7PM PST

const sfLocationsLink = `${v5Pages.locations}?city=san francisco`;

const useLocationConstants = ({locationId}: Params): {logo: PartnerLogoType; banners: Banner[]} => {
  const i18n = useTranslation();

  const saucLocsBody = i18n.t(
    "Southern Arizona Urgent Care is now powered by Carbon Health to bring you even better care.",
  );

  const med7Body = i18n.t(
    "Med7 Urgent Care is now powered by Carbon Health to bring you even better care.",
  );

  const locationBanners: Lookup<Banner[]> = {
    [LocationId.POPUP_BRANNON_SF]: [
      {
        text: (
          <Trans>
            This location is relocating. Please book an appointment at one of our other{" "}
            <a className="us gray800" href={sfLocationsLink}>
              San Francisco
            </a>{" "}
            locations.
          </Trans>
        ),
        btnText: i18n.t("View Locations"),
        href: sfLocationsLink,
        type: "warning",
      },
    ],
    [LocationId.LA_COSTA]: [
      {
        text: i18n.t("La Costa Urgent Care is now Carbon Health"),
      },
    ],
    [LocationId.SANTA_ANA]: [
      {
        text: i18n.t("Coastline Urgent Care is now Carbon Health"),
      },
    ],
    [LocationId.MOBILE_ALABAMA]: [
      {
        text: i18n.t("Hillcrest Urgent Care is now Carbon Health"),
      },
    ],
    [LocationId.SUNNYVALE]: [
      {
        text: i18n.t("Elite Medical is now Carbon Health"),
      },
    ],
    [LocationId.HILLS_PLAZA]: [
      {
        type: "info",
        text: i18n.t(
          "Due to the closing of our FiDi location, we are excited to welcome all former FiDi primary care patients to Hills Plaza.",
        ),
      },
    ],
    [LocationId.ATASCOCITA_TEXAS]: [
      {
        text: i18n.t("Urgent Care Mds - Atascocita is now Carbon Health"),
      },
    ],
    [LocationId.LITTLETON_COLORADO]: [
      {
        text: i18n.t("Urgent Care of CO is now Carbon Health"),
      },
    ],
    [LocationId.LONG_BEACH]: [
      {
        text: i18n.t("Urgent Care Plus is now Carbon Health"),
      },
    ],
    ...saucLocIds.reduce((acc, id) => acc.assign({[id]: [{text: saucLocsBody}]}), {}),
    ...med7LocationIds.reduce((acc, id) => acc.assign({[id]: [{text: med7Body}]}), {}),
    ...elCaminoLocIds.reduce(
      (acc, id) =>
        acc.assign({
          [id]: [
            {
              startTime: elCaminoDisabledTime,
              type: "warning",
              href: v5Pages.locations,
              btnText: i18n.t("Find a Carbon Health Location Near You"),
              text: i18n.t("Carbon Health is no longer operating out of this location."),
            },
          ],
        }),
      {},
    ),

    [LocationId.NEWBURY_PARK]: [
      {
        text: i18n.t(
          "Newbury Park Urgent Care is now powered by Carbon Health to bring you even better care.",
        ),
        logo: {
          src: "newburyParkLogo",
          extension: "svg",
          alt: i18n.t("Logo for Newbury Park."),
          width: 400,
        },
      },
    ],

    [LocationId.HIALEAH]: [
      {
        text: i18n.t(
          "Ritecare Urgent Care is now powered by Carbon Health to bring you even better care.",
        ),
        logo: {
          src: "ritecare-logo",
          extension: "svg",
          alt: i18n.t("Ritecare Urgent Care"),
        },
      },
    ],
    [LocationId.BROOKLYN]: [
      {
        text: (
          <Trans>
            Our Brooklyn COVID-19 testing location will be closed from 10/3 to 10/10 for
            renovations. It will reopen on 10/11. In the meantime, you can check out our other New
            York locations <a href={v5Pages.locations}>HERE</a>.
          </Trans>
        ),
        startTime: 1632891600000,
        endTime: 1633928400000,
        type: "info",
      },
    ],
    [LocationId.SAN_CLEMENTE_HYBRID_MD]: [
      {
        text: i18n.t(
          "Hybrid MD Urgent Care is now powered by Carbon Health to bring you even better care.",
        ),
        logo: {
          src: "hybrid-md-logo",
          extension: "svg",
          width: 300,
          height: 60,
        },
      },
    ],
    [LocationId.MY_INSTADOC_MONROE]: [
      {
        text: i18n.t(
          "My InstaDoc Urgent Care is now powered by Carbon Health to bring you even better care.",
        ),
        logo: {
          src: "my-instadoc-logo",
          width: 400,
          height: 60,
          extension: "png",
          objectFit: "cover",
        },
      },
    ],
    [LocationId.CENTRAL_NEW_JERSEY_SKILLMAN]: [
      {
        text: i18n.t(
          "Central New Jersey Urgent Care is now powered by Carbon Health to bring you even better care.",
        ),
        logo: {
          src: "cjuc-logo",
          width: 400,
          height: 60,
          extension: "png",
          objectFit: "cover",
        },
      },
    ],
  };

  const banners = (locationId && locationBanners[locationId]) || [];

  const locationLogos: PartnerLogoType[] = [
    {
      ids: stMarysLocIds,
      html: (
        <span className="df aic fs24 font-cm fw5 mt-2 mt0-sm fs14-sm">
          With
          <span className="df aic mh1 w5-sm">
            <NextImage
              className="db"
              src="stMarysLogo"
              extension="png"
              alt=""
              width={32}
              height={32}
            />
          </span>
          Saint Mary's Regional Medical Center
        </span>
      ),
    },
    {
      ids: saucLocIds,
      src: "sauc-logo",
      ext: "svg",
      width: 300,
      height: 60,
    },
    {
      ids: med7LocationIds,
      src: "med7-logo",
      ext: "svg",
      width: 300,
      height: 60,
    },
    {
      ids: johnMuirLocIds,
      html: <span className="db fs24 font-cm fw5">{i18n.t("A John Muir Health Partner")}</span>,
    },
  ];

  const logo = locationId && locationLogos.find(logos => logos.ids.includes(locationId));

  // @ts-expect-error TS2322: Type 'PartnerLogoType | undefined' is not assignable to type 'PartnerLogoType'.
  return {banners, logo};
};

export default useLocationConstants;
