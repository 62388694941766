"use client";
import Button from "@components/Button";
import Icon from "@components/Icon";
import {NextImageProps} from "@components/NextImage";
import {LinkProps} from "next/link";
import React from "react";

import {isInTimeRange} from "../../../utils/timeUtils";
import {BtnProps} from "../V5Comp";
import useLocationConstants, {isFullPageBanner} from "@constants/LocationConstants";
import {RootStateLocation} from "../../../store/types";

export type NoticeType = "info" | "warning" | "error" | "success";

export type Props = React.HTMLAttributes<HTMLDivElement> & {
  type?: NoticeType;
  text?: string | JSX.Element;
  subtext?: string;
  btnText?: string;
  href?: LinkProps["href"] | null;
  onCtaClick?: () => void;
  textClasses?: string;
  btnProps?: BtnProps;
  icon?: string;
  hideIcon?: boolean;
  logo?: NextImageProps;
  startTime?: number;
  endTime?: number;
  ignoreRange?: boolean;
  onClose?: () => void;
  location?: RootStateLocation;
};

const Notice: React.FC<Props> = ({
  type = "warning",
  text,
  href,
  onCtaClick,
  btnText,
  subtext,
  className,
  textClasses,
  btnProps,
  icon,
  hideIcon = false,
  startTime = Date.now(),
  endTime,
  ignoreRange,
  onClose,
  location,
  ...props
}) => {
  const modes = {
    success: {
      classes: "bg-green-200",
      icon: "info",
    },
    info: {
      classes: "bg-blue-200",
      icon: "info",
    },
    warning: {
      classes: "bg-yellow-200",
      icon: "warning",
    },
    error: {
      classes: "bg-red-200",
      icon: "warning",
    },
  };

  const bannerProps = useLocationConstants({locationId: location?.id})?.banners.filter(b =>
    isFullPageBanner(b),
  )[0];

  const buttonProps = {
    ...(btnProps || {}),
    ...(onCtaClick ? {onClick: onCtaClick} : {}),
    ...(href ? {href} : {}),
  };

  const showButton = Boolean(href || onCtaClick);

  return (!text && !subtext) || (!ignoreRange && !isInTimeRange(startTime, endTime)) ? null : (
    <div
      className={`p3 pl-4 br5 font-ir fs14 flex flex-row items-center sm:gap-2 ${
        modes[type].classes
      } ${className || ""}`}
      {...props}
      {...bannerProps}
      style={{
        width: showButton ? "100%" : "fit-content",
      }}
    >
      <div className="flex-1 flex flex-col sm:flex-row justify-center items-center">
        <div className="df aic pr2 mb2-xs">
          {!hideIcon && (
            <span className={`mr4 fs30 cIcon-${icon || modes[type].icon}`} aria-hidden />
          )}
          <div className={textClasses}>
            <strong className="font-ib db lh16">{text}</strong>
            <span className="lh14 db">{subtext}</span>
          </div>
        </div>
        {showButton && (
          <Button variant="outline" size="sm" {...buttonProps}>
            {btnText}
          </Button>
        )}
      </div>
      {onClose && (
        <Button variant="ghost" shape="circle" size="xs" className="border-none" onClick={onClose}>
          <Icon icon="close" />
        </Button>
      )}
    </div>
  );
};

export default Notice;
